<template>
  <v-container class="pt-0">
    <v-row v-if="upgradeProcess === true" justify="center" style="position: absolute;top: 46%; right: 50%;transform: translate(46%,-50%); width:100%">
      <v-col class="d-flex justify-center pb-0" cols="8">
        <v-img
          class="image"
          :src="require('../../assets/kyc/upgrade-process.png')"
        ></v-img>
      </v-col>
      <v-col class="py-0 pt-3" cols="12" align="center">
        <h3>{{$t('MembershipBeingProcessed')}}</h3>
        <span class="body-2">
          {{$t('DescribeSubmitStudentMember')}}
        </span>
      </v-col>
    </v-row>
    <v-row align="center" class="pl-6 py-3 background" v-if="upgradeProcess == false">
      <v-col cols="4" class="pr-0">
        <img
          :src="require('../../assets/kyc/premium-page-header.svg')">
      </v-col>
      <v-col cols="8" class="white--text pl-0">
        <h3>{{$t("LetsUpgradeYourAccount")}}!</h3>
        <p class="mb-0">{{$t("EnjoyMoreBenefitsAndCompleteAccessToAllServices")}}</p>
      </v-col>
    </v-row>
    <div class="PremiumMember" v-if="upgradeProcess == false">
      <div class="content">
        <v-container>
          <v-row no-gutters align="center">
            <v-col class="12">
              <v-card outlined class="secondary lighten-5 ma-1 rounded-card onHover" @click="ClickReqPremium()">
                <v-list class="transparent">
                  <v-list-item>
                    <v-row align="center">
                      <v-col cols="4">
                      <img 
                        :src="require('../../assets/kyc/icon-premium-page.svg')"/>
                      </v-col>
                      <v-col  cols="8">
                        <h3 class="primary--text">{{$t("Premium")}}</h3>
                        <p class="caption mb-0">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                      </v-col>
                    </v-row>
                  </v-list-item>
                </v-list>
              </v-card>
            </v-col>
            <v-col cols="12">
              <v-card outlined class="secondary lighten-5 ma-1 mt-3 rounded-card onHover" @click="ClickReqStudent()">
                <v-list class="transparent">
                  <v-list-item>
                    <v-row align="center">
                      <v-col cols="4">
                        <img 
                          :src="require('../../assets/kyc/user-student.svg')"/>
                      </v-col>
                      <v-col  cols="8">
                        <h3 class="primary--text">{{$t("Student")}}</h3>
                        <p class="caption mb-0">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                      </v-col>
                    </v-row>
                  </v-list-item>
                </v-list>
              </v-card>
            </v-col>
          </v-row>
        </v-container>
      </div>
    </div>
</v-container>
</template>

<script>
// @ is an alias to /src

export default {
  name: "PremiumMember",
  components: {},
  data() {
    return {
      valueStudent: 0,
      bufferValueStudent: 90,
      colors: [
        "green",
        "secondary",
        "yellow darken-4",
        "red lighten-2",
        "orange darken-1",
      ],
      cycle: false,
      upgradeProcess : false,
      upgradeType : ''
    };
  },
  methods:{
    ClickReqStudent(){
      if(this.$store.state.auth.user_role == 'PASSENGER-BASIC' ){
        this.$router.push({
          name: 'ReqStudent',
        })
      }
      // else if(this.$store.state.auth.user_role == 'PASSENGER-STUDENT' ){
      //   this.$router.push({
      //     name: 'PremiumMember',
      //   })
      // }else{
      //   this.$router.push({
      //     name: 'PremiumMember',
      //   })
      // }
    },
    ClickReqPremium(){
      if(this.$store.state.auth.user_role == 'PASSENGER-BASIC' ){
        this.$router.push({
          name: 'PremiumReq',
        })
      }
      // else if(this.$store.state.auth.user_role == 'PASSENGER-STUDENT' ){
      //   this.$router.push({
      //     name: 'PremiumMember',
      //   })
      // }else{
      //   this.$router.push({
      //     name: 'PremiumMember',
      //   })
      // }
    },
  },
  created(){
    this.$store
      .dispatch("passenger/premiumChecking")
      .then((response) => {
        if (response[0].is_member == true) {
          this.upgradeProcess = true
        }
      })
      .catch((err) => {
        console.log(err);
      });
  },

};
</script>

<style lang="scss" scoped>
.background {
  background: #479D4A;
  background-image: url(/img/header-decorations.1a1215dc.svg), linear-gradient(180deg, #479D4A 0%, #5DB960 100%);
  background-position: right bottom;
  background-repeat: no-repeat;
}
.onHover{
  cursor: pointer;
}
/* If the screen size is 601px or more, set the font-size of <div> to 80px */
@media only screen and (min-width: 601px) {

  .rounded-card {
    border-radius: 15px !important;
    background: linear-gradient(180deg, #FFFFFF 0%, #FFFFFF 100%);
  }
  .content{
    z-index: 50%;
  }
}

/* If the screen max size is 600px, set the font-size of <div> to 80px */
@media only screen and (max-width: 600px) {
  .rounded-card {
    border-radius: 15px !important;
    background: linear-gradient(180deg, #FFFFFF 0%, #FFFFFF 100%);
  }
  .content{
    z-index: 50%;
  }
}
</style>